import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import WeldDefectConnector, { WeldDefect } from '../../../connectors/WeldDefectConnector';
import { useEffect, useState } from 'react';
import WeldDefectItem from './WeldDefectItem/WeldDefectItem';
import Page from '../../Page/Page';

const BodySection = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 0;
    gap: 15px;
    overflow-y: auto;
    padding: 0 20px 20px 20px;
`;

const WeldDefectsPage = () => {
    const navigate = useNavigate();
    const [weldDefects, setWeldDefects] = useState<WeldDefect[]>([]);

    useEffect(() => {
        WeldDefectConnector.getWeldDefects().then((fetchedWeldDefects) => {
            setWeldDefects(fetchedWeldDefects);
        });
    }, []);

    return (
        <Page title="Weld Defects">
            <BodySection>
                {weldDefects.map((weldDefect) => (
                    <WeldDefectItem
                        key={weldDefect.id}
                        weldDefect={weldDefect}
                        onClick={() => {
                            navigate(`/weld-defects/${weldDefect.id}`);
                        }}
                    />
                ))}
            </BodySection>
        </Page>
    );
};

export default WeldDefectsPage;
