import styled from '@emotion/styled';
import { WeldDefect } from '../../../../connectors/WeldDefectConnector';

const Item = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    max-width: 400px;
    cursor: pointer;
    gap: 10px;
    padding: 0 10px;

    &:hover {
        background-color: #f9f9f9;
    }

    @media (max-width: 1054px) {
        border-bottom: 1px solid #ccc;
    }

    @media (min-width: 1054px) {
        border: 1px solid #ccc;
        border-radius: 5px;
    }
`;

const Label = styled.div`
    min-width: 50px;
    color: #e3140e;
`;

const Value = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export interface Props {
    weldDefect: WeldDefect;
    onClick: () => void;
}

const WeldDefectItem = ({ weldDefect, onClick }: Props) => {
    return (
        <Item onClick={onClick}>
            <Label>{weldDefect.code}</Label>
            <Value>{weldDefect.description}</Value>
        </Item>
    );
};

export default WeldDefectItem;
