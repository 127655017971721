import styled from '@emotion/styled';
import RailMarkingItem from './RailMarkingItem/RailMarkingItem';
import { useNavigate } from 'react-router-dom';
import MarkingConnector, { Marking } from '../../../connectors/MarkingConnector';
import { useEffect, useState } from 'react';
import Page from '../../Page/Page';

const BodySection = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 0;
    gap: 15px;
    overflow-y: auto;
    padding-bottom: 20px;
`;

const RailMarkingsPage = () => {
    const navigate = useNavigate();
    const [markings, setMarkings] = useState<Marking[]>([]);

    useEffect(() => {
        MarkingConnector.getMarkings().then((fetchedMarkings) => {
            setMarkings(fetchedMarkings);
        });
    }, []);

    return (
        <Page title="Rail Markings">
            <BodySection>
                {markings.map((marking) => (
                    <RailMarkingItem
                        key={marking.id}
                        railMarking={marking}
                        onClick={() => {
                            navigate(`/rail-markings/${marking.id}`);
                        }}
                    />
                ))}
            </BodySection>
        </Page>
    );
};

export default RailMarkingsPage;
