export type WeldLookupData = {
    sections: { [key: number]: { name: string } };
    grades: { [key: number]: { name: string } };
    processes: { [key: number]: { name: string } };
    crucibles: { [key: number]: { name: string } };
    fuels: { [key: number]: { name: string } };
    lookup: { [key: number]: { [key: number]: { [key: number]: { [key: number]: { [key: number]: number } } } } };
};

export default class WeldLookupConnector {
    static async getWeldLookup(): Promise<WeldLookupData> {
        const url = `${this.getBaseUrl()}/api/weld-lookup`;

        const response = await fetch(url);
        return this.mapWeldLookup(await response.json());
    }

    static async getWeldLookupDetails(id: number): Promise<WeldLookupData> {
        const url = `${this.getBaseUrl()}/api/weld-lookup/${id}`;

        const response = await fetch(url);
        return this.mapWeldLookupDetails(await response.json());
    }

    static mapWeldLookup = (rawWeldLookup: any): WeldLookupData => ({
        sections: rawWeldLookup.sections,
        grades: rawWeldLookup.grades,
        processes: rawWeldLookup.processes,
        crucibles: rawWeldLookup.crucibles,
        fuels: rawWeldLookup.fuels,
        lookup: rawWeldLookup.lookup,
    });

    static mapWeldLookupDetails = (rawWeldLookupDetails: any): any => rawWeldLookupDetails;

    private static getBaseUrl = (): string => {
        return process.env.REACT_APP_BACKEND_HOST || '';
    };
}
