import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import WeldDefectConnector, { WeldDefect } from '../../../connectors/WeldDefectConnector';
import Page from '../../Page/Page';

const MarkingImage = styled.img`
    width: 80%;
    max-width: 400px;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
`;

const BodySection = styled(Section)`
    width: 100%;
    padding-top: 0;
    overflow-y: auto;
`;

const Label = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-weight: bold;
`;

const Value = styled.div`
    display: flex;
    align-items: center;
`;

const ImageContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

const DetailsContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 400px;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
`;

const WeldDefectDetailsPage = () => {
    let { weldDefectId } = useParams();
    const [weldDefect, setWeldDefect] = useState<WeldDefect | null>(null);

    useEffect(() => {}, []);

    useEffect(() => {
        if (weldDefectId !== undefined) {
            WeldDefectConnector.getWeldDefect(Number(weldDefectId)).then((fetchedWeldDefect) => {
                setWeldDefect(fetchedWeldDefect);
            });
        }
    }, [weldDefectId]);

    return (
        <Page title="Weld Defect" backButton={true}>
            <BodySection>
                <ImageContainer>
                    {weldDefect?.imageUrl && <MarkingImage src={weldDefect?.imageUrl} alt="marking-img" />}
                </ImageContainer>
                <DetailsContainer>
                    <Label>Defect Code:</Label>
                    <Value>{weldDefect?.code}</Value>
                    <Label>Weld Feature: </Label>
                    <Value>{weldDefect?.weldFeature || '-'}</Value>
                    <Label>Inspection: </Label>
                    <Value>{weldDefect?.inspection || '-'}</Value>
                    <Label>Criteria: </Label>
                    <Value>{weldDefect?.criteria || '-'}</Value>
                </DetailsContainer>
            </BodySection>
        </Page>
    );
};

export default WeldDefectDetailsPage;
