import styled from '@emotion/styled';
import { Button } from '@mui/material';
import Page from '../../Page/Page';

const Subtitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
`;

const StyledButton = styled(Button)`
    margin-top: 20px;
    width: 200px;
    background-color: #d6d8d7;
    border: none;
    height: 40px;
    font-weight: bold;
    cursor: pointer;
    background-color: rgb(227, 0, 15);
    color: white;

    &:hover {
        background-color: #aa0419;
    }
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
`;

const HomePage = () => {
    const handleButtonClick = () => {
        window.open('https://www.goldschmidt.com/en/', '_blank');
    };

    return (
        <Page title="Welcome" subtitle="You can use the menu button in the top left corner to navigate the app">
            <Section>
                <Subtitle>IMPORTANT: Only ever use your phone from a position of safety</Subtitle>
                <StyledButton onClick={handleButtonClick}>VISIT WEBSITE</StyledButton>
            </Section>
        </Page>
    );
};

export default HomePage;
