import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import WeldLookupConnector, { WeldLookupData } from '../../../connectors/WeldLookupConnector';
import { useEffect, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box, Button, FormControl, InputLabel, MenuItem } from '@mui/material';
import Page from '../../Page/Page';

const BodySection = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 0;
    gap: 15px;
    overflow-y: auto;
    padding: 10px 20px 20px 20px;
`;

const StyledBox = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

type Option = {
    label: String;
    value: number;
};

const WeldLookupPage = () => {
    const navigate = useNavigate();

    const mockWeldLookupData: WeldLookupData = {
        sections: {},
        grades: {},
        processes: {},
        crucibles: {},
        fuels: {},
        lookup: {},
    };

    const [weldLookupData, setWeldLookupData] = useState<WeldLookupData>(mockWeldLookupData);
    const [railSection, setRailSection] = useState<string>('');
    const [railGrade, setRailGrade] = useState<string>('');
    const [weldProcess, setWeldProcess] = useState<string>('');
    const [crucible, setCrucible] = useState<string>('');
    const [fuelType, setFuelType] = useState<string>('');

    const [railSectionOptions, setRailSectionOptions] = useState<Option[]>([]);
    const [railGradeOptions, setRailGradeOptions] = useState<Option[]>([]);
    const [weldProcessOptions, setWeldProcessOptions] = useState<Option[]>([]);
    const [crucibleOptions, setCrucibleOptions] = useState<Option[]>([]);
    const [fuelOptions, setFuelOptions] = useState<Option[]>([]);

    const [railSectionSelectorEnabled, setRailSectionSelectorEnabled] = useState<boolean>(false);
    const [railGradeSelectorEnabled, setRailGradeSelectorEnabled] = useState<boolean>(false);
    const [weldProcessSelectorEnabled, setWeldProcessSelectorEnabled] = useState<boolean>(false);
    const [crucibleSelectorEnabled, setCrucibleSelectorEnabled] = useState<boolean>(false);
    const [fuelSelectorEnabled, setFuelSelectorEnabled] = useState<boolean>(false);

    useEffect(() => {
        WeldLookupConnector.getWeldLookup().then((fetchedWeldLookupData) => {
            setWeldLookupData(fetchedWeldLookupData);
        });
    }, []);

    useEffect(() => {
        if (weldLookupData.sections) {
            setRailSectionOptions(
                Object.keys(weldLookupData.lookup).map((key: any) => {
                    return { label: weldLookupData.sections[key].name, value: parseInt(key) };
                })
            );
            setRailSectionSelectorEnabled(true);
        } else {
            setRailSectionSelectorEnabled(false);
        }
    }, [weldLookupData]);

    useEffect(() => {
        setRailGrade('');
        setWeldProcess('');
        setCrucible('');
        setFuelType('');

        if (railSection) {
            const railSectionId = parseInt(railSection);
            setRailGradeOptions(
                Object.keys(weldLookupData.lookup[railSectionId]).map((key: any) => {
                    return { label: weldLookupData.grades[key].name, value: parseInt(key) };
                })
            );
            setRailGradeSelectorEnabled(true);
        } else {
            setRailGradeSelectorEnabled(false);
        }
    }, [railSection]);

    useEffect(() => {
        setWeldProcess('');
        setCrucible('');
        setFuelType('');

        if (railGrade) {
            const railSectionId = parseInt(railSection);
            const railGradeId = parseInt(railGrade);
            setWeldProcessOptions(
                Object.keys(weldLookupData.lookup[railSectionId][railGradeId]).map((key: any) => {
                    return { label: weldLookupData.processes[key].name, value: parseInt(key) };
                })
            );
            setWeldProcessSelectorEnabled(true);
        } else {
            setWeldProcessSelectorEnabled(false);
        }
    }, [railGrade]);

    useEffect(() => {
        setCrucible('');
        setFuelType('');

        if (weldProcess) {
            const railSectionId = parseInt(railSection);
            const railGradeId = parseInt(railGrade);
            const weldProcessId = parseInt(weldProcess);
            setCrucibleOptions(
                Object.keys(weldLookupData.lookup[railSectionId][railGradeId][weldProcessId]).map((key: any) => {
                    return { label: weldLookupData.crucibles[key].name, value: parseInt(key) };
                })
            );
            setCrucibleSelectorEnabled(true);
        } else {
            setCrucibleSelectorEnabled(false);
        }
    }, [weldProcess]);

    useEffect(() => {
        setFuelType('');

        if (crucible) {
            const railSectionId = parseInt(railSection);
            const railGradeId = parseInt(railGrade);
            const weldProcessId = parseInt(weldProcess);
            const crucibleId = parseInt(crucible);
            setFuelOptions(
                Object.keys(weldLookupData.lookup[railSectionId][railGradeId][weldProcessId][crucibleId]).map(
                    (key: any) => {
                        return { label: weldLookupData.fuels[key].name, value: parseInt(key) };
                    }
                )
            );
            setFuelSelectorEnabled(true);
        } else {
            setFuelSelectorEnabled(false);
        }
    }, [crucible]);

    const handleRailSectionChange = (event: SelectChangeEvent) => {
        setRailSection(event.target.value as string);
    };

    const handleRailGradeChange = (event: SelectChangeEvent) => {
        setRailGrade(event.target.value as string);
    };

    const handleWeldProcessChange = (event: SelectChangeEvent) => {
        setWeldProcess(event.target.value as string);
    };

    const handleCrucibleChange = (event: SelectChangeEvent) => {
        setCrucible(event.target.value as string);
    };

    const handleFuelChange = (event: SelectChangeEvent) => {
        setFuelType(event.target.value as string);
    };

    const disabled = !railSection || !railGrade || !weldProcess || !crucible || !fuelType;

    let id: number | null = null;
    if (fuelType) {
        try {
            id =
                weldLookupData.lookup[parseInt(railSection)][parseInt(railGrade)][parseInt(weldProcess)][
                    parseInt(crucible)
                ][parseInt(fuelType)];
        } catch {
            id = null;
        }
    }

    return (
        <Page title="Weld Lookup">
            <BodySection>
                <StyledBox sx={{ minWidth: 300 }}>
                    <FormControl fullWidth>
                        <InputLabel id="rail-section-selector-label">Rail Section</InputLabel>
                        <Select
                            id="rail-section-selector"
                            labelId="rail-section-selector-label"
                            value={railSection}
                            onChange={handleRailSectionChange}
                            label="Rail Section"
                            disabled={!railSectionSelectorEnabled}
                        >
                            {railSectionOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel id="rail-grade-selector-label">Rail Grade</InputLabel>
                        <Select
                            id="rail-grade-selector"
                            labelId="rail-grade-selector-label"
                            value={railGrade}
                            onChange={handleRailGradeChange}
                            label="Rail Grade"
                            disabled={!railGradeSelectorEnabled}
                        >
                            {railGradeOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel id="weld-rocess-selector-label">Weld Process</InputLabel>
                        <Select
                            id="weld-rocess-selector"
                            labelId="weld-rocess-selector-label"
                            value={weldProcess}
                            onChange={handleWeldProcessChange}
                            label="Weld Process"
                            disabled={!weldProcessSelectorEnabled}
                        >
                            {weldProcessOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel id="crucible-selector-label">Crucible</InputLabel>
                        <Select
                            id="crucible-selector"
                            labelId="crucible-selector-label"
                            value={crucible}
                            onChange={handleCrucibleChange}
                            label="Crucible"
                            disabled={!crucibleSelectorEnabled}
                        >
                            {crucibleOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel id="fuel-selector-label">Fuel</InputLabel>
                        <Select
                            id="fuel-selector"
                            labelId="fuel-selector-label"
                            value={fuelType}
                            onChange={handleFuelChange}
                            label="Fuel"
                            disabled={!fuelSelectorEnabled}
                        >
                            {fuelOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button disabled={disabled} variant="contained" onClick={() => navigate(`/weld-lookup/${id}`)}>
                        Look up
                    </Button>
                </StyledBox>
            </BodySection>
        </Page>
    );
};

export default WeldLookupPage;
