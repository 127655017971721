import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

const Title = styled.div`
    font-size: 30px;
    color: rgb(227, 0, 15);
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 15px;
`;

const Subtitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
`;

const StyledImage = styled.img`
    width: 80%;
    max-width: 400px;
    margin-top: 20px;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const BackButton = styled.button`
    position: absolute;
    right: 15px;
    top: 60px;
    border: none;
    border-radius: 20px;
    width: 35px;
    height: 35px;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
`;

export interface Props {
    title?: string;
    subtitle?: string;
    backButton?: boolean;
}

const PageHeader = ({ title, subtitle, backButton = false }: Props) => {
    const navigate = useNavigate();

    return (
        <Section>
            {backButton && <BackButton onClick={() => navigate(-1)}>X</BackButton>}
            <StyledImage src="/img/gol-logo.svg" alt="Logo" />
            {title && <Title>{title}</Title>}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Section>
    );
};

export default PageHeader;
