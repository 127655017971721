import styled from '@emotion/styled';
import { Marking } from '../../../../connectors/MarkingConnector';

const SummaryContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    border-top: 1px solid #ccc;
    padding: 10px;
`;

const StyledImage = styled.img`
    height: 100%;
`;

const ImageContainer = styled.div`
    display: flex;
    max-height: 80px;
    height: 100px;
    width: 80%;
    justify-content: center;
    align-items: center;
`;

const Item = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 160px;
    max-width: 350px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #f9f9f9;
    }
`;

const Label = styled.div`
    width: 60px;
`;

const Value = styled.div`
    color: #e3140e;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
`;

export interface Props {
    railMarking: Marking;
    onClick: () => void;
}

const RailMarkingItem = ({ railMarking, onClick }: Props) => {
    return (
        <Item onClick={onClick}>
            <ImageContainer>
                <StyledImage src={railMarking.imageUrl} alt="marking-img" />
            </ImageContainer>
            <SummaryContainer>
                <Row>
                    <Label>Grade:</Label>
                    <Value>{railMarking.grade}</Value>
                </Row>
                <Row>
                    <Label>Portion:</Label>
                    <Value>{railMarking.portion}</Value>
                </Row>
            </SummaryContainer>
        </Item>
    );
};

export default RailMarkingItem;
