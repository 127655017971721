import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import WeldLookupConnector from '../../../connectors/WeldLookupConnector';
import Page from '../../Page/Page';

const Title = styled.div`
    font-size: 30px;
    color: rgb(227, 0, 15);
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 15px;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
`;

const BodySection = styled(Section)`
    width: 100%;
    padding-top: 0;
    overflow-y: auto;
`;

const Label = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`;

const Value = styled.div`
    display: flex;
    align-items: center;
    color: #e3140e;
`;

const DetailsContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 400px;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
`;

const Item = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

type Property = {
    label: string;
    value: string;
};

const WeldLookupDetailsPage = () => {
    let { id } = useParams();
    const [details, setDetails] = useState<any | null>(null);

    const selectionParameters: Property[] = [
        { label: 'Rail Section', value: 'section' },
        { label: 'Rail Grade', value: 'grade' },
        { label: 'Weld Proccess', value: 'process' },
        { label: 'Crucible', value: 'crucible' },
        { label: 'Fuel type', value: 'fuel' },
    ];

    const parameters: Property[] = [
        { label: 'Portion Type', value: 'portion_type' },
        { label: 'Portion Size', value: 'portion_size' },
        { label: 'Weld Gap', value: 'weld_gap' },
        { label: 'Burner Type', value: 'burner_type' },
        { label: 'Burner Height', value: 'burner_height' },
        { label: 'Tapping Band', value: 'tapping_band' },
        { label: 'Oxygen', value: 'oxigen_pressure' },
        { label: 'Fuel Pressure', value: 'fuel_pressure' },
        { label: 'Cone Length', value: 'cone_length' },
        { label: 'Preheat Time', value: 'preheat_time' },
        { label: 'Remove Crucible', value: 'remove_crucible' },
        { label: 'Remove Slag Bowls', value: 'remove_slag_bowls' },
        { label: 'Remove UV Mount', value: 'remove_uv_mount' },
        { label: 'Remove Mould Shoes', value: 'remove_mold_shoes' },
        { label: 'Start Trimming', value: 'start_trimming' },
        { label: 'Remove Wedges', value: 'remove_wedges' },
        { label: 'Replace Pads & Fast', value: 'replace_pads_and_fast' },
        { label: 'Start Grinding', value: 'start_grinding' },
    ];

    useEffect(() => {}, []);

    useEffect(() => {
        if (id !== undefined) {
            WeldLookupConnector.getWeldLookupDetails(Number(id)).then((fetchedDetails) => {
                setDetails(fetchedDetails);
            });
        }
    }, [id]);

    return (
        <Page backButton={true}>
            {details && (
                <BodySection>
                    <Title>Chosen Parameters</Title>
                    <DetailsContainer>
                        {selectionParameters.map((parameter) => (
                            <Item key={parameter.label}>
                                <Label>{parameter.label}:</Label>
                                <Value>{details.selection[parameter.value].name}</Value>
                            </Item>
                        ))}
                    </DetailsContainer>
                    <Title>Results</Title>
                    <DetailsContainer>
                        {parameters.map((parameter) => (
                            <Item key={parameter.label}>
                                <Label>{parameter.label}:</Label>
                                <Value>{details.parameters[parameter.value]}</Value>
                            </Item>
                        ))}
                    </DetailsContainer>
                </BodySection>
            )}
        </Page>
    );
};

export default WeldLookupDetailsPage;
