import styled from 'styled-components';
import PageHeader from '../PageHeader/PageHeader';

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 20px;
`;

const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export interface Props {
    title?: string;
    subtitle?: string;
    backButton?: boolean;
    children: React.ReactNode;
}

const Page = ({ title, subtitle, children, backButton = false }: Props) => (
    <PageContainer>
        <PageHeader title={title} subtitle={subtitle} backButton={backButton} />
        <BodyContainer>{children}</BodyContainer>
    </PageContainer>
);

export default Page;
