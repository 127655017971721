import styled from '@emotion/styled';
import { Button, InputAdornment, OutlinedInput } from '@mui/material';
import { useState } from 'react';
import Page from '../../Page/Page';

const Subtitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
`;

const CalculatorImage = styled.img`
    width: 95%;
    max-width: 800px;
    margin-top: 20px;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const BodySection = styled(Section)`
    margin-top: 20px;
`;

const Disclaimer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
`;

const CalculatorRow = styled.div`
    display: flex;
    height: 30px;
    flex-direction: row;
    align-items: space-between;
    justify-content: center;
    margin-top: 20px;
`;

const Label = styled.div`
    display: flex;
    width: 120px;
    align-items: center;
`;

const StyledOutlinedInput = styled(OutlinedInput)`
    height: 30px;
    width: 110px;
    margin: 0 5px;

    & .MuiOutlinedInput-input {
        text-align: center;
        -moz-appearance: textfield;
        -webkit-appearance: textfield;
        margin: 0;
    }

    input::-webkit-outer-spin-button {
        display: none;
    }

    input::-webkit-inner-spin-button {
        display: none;
    }

    input[type=number] {
        MozAppearance: textfield;
    },

`;

const StyledButton = styled(Button)`
    width: 40px;
    min-width: 40px;
    height: 30px;
    background-color: #d6d8d7;
    border: none;
    font-weight: bold;
    cursor: pointer;
    background-color: grey;
    color: white;

    & MuiTouchRipple-root {
        width: 25px;
    }

    &:hover {
        background-color: #aa0419;
    }
`;

type Row = {
    label: string;
    onSet: (value: number) => void;
    min: number;
    max: number;
    value: number;
    units: string;
};

const RailExtensionPage = () => {
    const COEFF = 11.5e-6;

    const DEFAULT_FREE_RAIL_LENGTH_IN_METERS = 30;
    const DEFAULT_TARGET_SFC_IN_CELSIUS = 27;
    const DEFAULT_AMBIENT_TEMPERATURE_IN_CELSIUS = 17;
    const MIN_FREE_RAIL_LENGTH_IN_METERS = 0;
    const MAX_FREE_RAIL_LENGTH_IN_METERS = 9999.99;
    const MIN_TARGET_SFC_IN_CELSIUS = 0;
    const MAX_TARGET_SFC_IN_CELSIUS = 99;
    const MIN_AMBIENT_TEMPERATURE_IN_CELSIUS = -99;
    const MAX_AMBIENT_TEMPERATURE_IN_CELSIUS = 99;

    const lengthUnits = 'm';
    const temperatureUnits = '°C';

    let defaultFreeRailLength = DEFAULT_FREE_RAIL_LENGTH_IN_METERS;
    let defaultTargetSFT = DEFAULT_TARGET_SFC_IN_CELSIUS;
    let defaultAmbientTemp = DEFAULT_AMBIENT_TEMPERATURE_IN_CELSIUS;
    let minFreRailLength = MIN_FREE_RAIL_LENGTH_IN_METERS;
    let maxFreeRailLength = MAX_FREE_RAIL_LENGTH_IN_METERS;
    let minTargetSFT = MIN_TARGET_SFC_IN_CELSIUS;
    let maxTargetSFT = MAX_TARGET_SFC_IN_CELSIUS;
    let minAmbientTemp = MIN_AMBIENT_TEMPERATURE_IN_CELSIUS;
    let maxAmbientTemp = MAX_AMBIENT_TEMPERATURE_IN_CELSIUS;

    const [freeRailLength, setFreeRailLength] = useState<number>(defaultFreeRailLength);
    const [targetSFT, setTargetSFT] = useState<number>(defaultTargetSFT);
    const [ambientTemp, setAmbientTemp] = useState<number>(defaultAmbientTemp);

    const checkValue = (value: number, min: number, max: number) => {
        if (value < min) {
            return min;
        }
        if (value > max) {
            return max;
        }
        return value;
    };

    const handleSetRailLength = (value: number) => {
        setFreeRailLength(checkValue(value, minFreRailLength, maxFreeRailLength));
    };

    const handleSetTargetSFT = (value: number) => {
        setTargetSFT(checkValue(value, minTargetSFT, maxTargetSFT));
    };

    const handleSetAmbientTemp = (value: number) => {
        setAmbientTemp(checkValue(value, minAmbientTemp, maxAmbientTemp));
    };

    const rows: Row[] = [
        {
            label: 'Free rail length',
            onSet: handleSetRailLength,
            min: minFreRailLength,
            max: maxFreeRailLength,
            value: freeRailLength,
            units: lengthUnits,
        },
        {
            label: 'Target SFT',
            onSet: handleSetTargetSFT,
            min: minTargetSFT,
            max: maxTargetSFT,
            value: targetSFT,
            units: temperatureUnits,
        },
        {
            label: 'Ambient temp',
            onSet: handleSetAmbientTemp,
            min: minAmbientTemp,
            max: maxAmbientTemp,
            value: ambientTemp,
            units: temperatureUnits,
        },
    ];

    const renderRows = (rows: any) =>
        rows.map((row: Row) => (
            <CalculatorRow key={row.label}>
                <Label>{row.label}:</Label>
                <StyledButton
                    variant="contained"
                    onClick={() => row.onSet(row.value - 1)}
                    disabled={row.value <= row.min}
                >
                    -
                </StyledButton>
                <StyledOutlinedInput
                    type="number"
                    value={row.value.toFixed(2)}
                    onChange={(event) => row.onSet(Number(event.target.value))}
                    endAdornment={<InputAdornment position="end">{row.units}</InputAdornment>}
                    inputProps={{
                        min: row.min,
                        max: row.max,
                    }}
                />
                <StyledButton
                    variant="contained"
                    onClick={() => row.onSet(row.value + 1)}
                    disabled={row.value >= row.max}
                >
                    +
                </StyledButton>
            </CalculatorRow>
        ));

    const calculateExtension = () => {
        const L = freeRailLength;
        const SFT = targetSFT;
        const RT = ambientTemp;
        return L * 1000 * COEFF * (SFT - RT);
    };

    return (
        <Page title="Rail Extension">
            <BodySection>
                <Subtitle>Re-stressing Schematic</Subtitle>
                <CalculatorImage src="/img/calculator.svg" alt="Calculator" />
            </BodySection>
            <BodySection>
                <Subtitle>Extension Calculator</Subtitle>
                {renderRows(rows)}
            </BodySection>
            <BodySection>
                <Subtitle>
                    Calculated Extension: {calculateExtension().toFixed(2)}
                    mm
                </Subtitle>
            </BodySection>
            <Disclaimer>
                Please note: This calculator has been built using data provided by Thermit GB. It may not be relevant to
                your territory.
            </Disclaimer>
        </Page>
    );
};

export default RailExtensionPage;
