export type Marking = {
    id: number;
    grade: string;
    formerGrade: string;
    brand: string;
    portion: string;
    composition: string;
    imageUrl: string;
};

export default class MarkingConnector {
    static async getMarkings(): Promise<Marking[]> {
        const url = `${this.getBaseUrl()}/api/rail-markings`;

        const response = await fetch(url);
        return this.mapMarkings(await response.json());
    }

    static async getMarking(id: number): Promise<Marking> {
        const url = `${this.getBaseUrl()}/api/rail-markings/${id}`;

        const response = await fetch(url);
        return this.mapMarkings([await response.json()])[0];
    }

    static mapMarkings = (rawMarkings: any[]) => {
        const protocol = window.location.protocol;
        const hostname = window.location.hostname;
        const baseUrl = `${protocol}//${hostname}`;

        return rawMarkings.map((rawMarking: any) => ({
            id: rawMarking.id,
            grade: rawMarking.grade,
            formerGrade: rawMarking.former_grade,
            brand: rawMarking.brand,
            portion: rawMarking.portion,
            composition: rawMarking.composition,
            imageUrl: `${baseUrl}/${rawMarking.image_url}`,
        }));
    };

    private static getBaseUrl = () => {
        return process.env.REACT_APP_BACKEND_HOST || '';
    };
}
