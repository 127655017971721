export type WeldDefect = {
    id: number;
    code: string;
    description: string;
    weldFeature: string;
    inspection: string;
    criteria: string;
    imageUrl: string;
};

export default class WeldDefectConnector {
    static async getWeldDefects(): Promise<WeldDefect[]> {
        const url = `${this.getBaseUrl()}/api/weld-defects`;

        const response = await fetch(url);
        return this.mapWeldDefects(await response.json());
    }

    static async getWeldDefect(id: number): Promise<WeldDefect> {
        const url = `${this.getBaseUrl()}/api/weld-defects/${id}`;

        const response = await fetch(url);
        return this.mapWeldDefects([await response.json()])[0];
    }

    static mapWeldDefects = (rawWeldDefects: any[]): WeldDefect[] => {
        const protocol = window.location.protocol;
        const hostname = window.location.hostname;
        const baseUrl = `${protocol}//${hostname}`;

        return rawWeldDefects.map((rawWeldDefect: any) => ({
            id: rawWeldDefect.id,
            code: rawWeldDefect.code,
            description: rawWeldDefect.description,
            weldFeature: rawWeldDefect.weld_feature,
            inspection: rawWeldDefect.inspection,
            criteria: rawWeldDefect.criteria,
            imageUrl: rawWeldDefect.image_url ? `${baseUrl}/${rawWeldDefect.image_url}` : '',
        }));
    };

    private static getBaseUrl = (): string => {
        return process.env.REACT_APP_BACKEND_HOST || '';
    };
}
